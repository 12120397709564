import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import bellicon from "../../../../assets/images/bellicon.png";
import ltcicon from "../../../../assets/images/ltcicon.png";
// import home from "../../../../assets/images/home.png";
// import ahome from "../../../../assets/images/ahome.png";
// import course from "../../../../assets/images/course.png";
// import academicection from "../../../../assets/images/academicection.png";
// import examsection from "../../../../assets/images/examsection.png";
// import setfacility from "../../../../assets/images/setfacility.png";
// import faq from "../../../../assets/images/faq.png";
import axios from 'axios';
import { getData, getPublicData } from '../../../../adapters/moodleServices';
import config from '../../../../utils/config';



interface SidebarNotificationData {
  totalnotificationcount: number|any;
  ltcnotificationcount: number|any;
  htmldata: string|any;
}

interface SidebarMenuData {
  htmldata: string|any;
}

const SidebarContent = () => {
    const [sidebarNotificationData, setSidebarNotificationData] = useState<SidebarNotificationData | null|any>(null);
  const [sidebarMenu, setSidebarMenu] = useState<SidebarMenuData | null>(null);
  const isAdmin = useSelector((state: any) => state.user.isAdmin);
  const isLoggedIn = useSelector((state: any) => state.user.loggedIn);
  const userid: number | null = useSelector((state: any) => state.user.userId);
  const [publicToken, setPublicToken] = useState({})

  const baseURL = config.CORE_SERVICE_URL;


  useEffect(() => {
    const fetchUrlData = async () => {
      try {
        const result = await axios.get(`${baseURL}/public/institute/details`);
        if (result.data && result.status === 200) {
          setPublicToken(result.data);
          localStorage.setItem("publicWebServiceToken", result.data.publicWebServiceToken);
          localStorage.setItem("MoodleBaseUrl", result.data.instanceUrl);
        }
      } catch (err) {
        console.error('Failed to load URLs:', err);
      }
    };

    const fetchPulicSidebarMenu = async () => {
      console.log("fetchUrlData")
      const query1 = { wsfunction: 'local_seatcreation_sidebar_menu'};
      try {
        const res = await getPublicData(query1, isLoggedIn);
        console.log("fetchUrlData 2")
        if (res.status === 200 && res.data && !res.data.errorcode) {
          setSidebarMenu(res.data as SidebarMenuData);
        }
      } catch (err) {
        console.error(err);
      }
    };

    const fetchSidebarMenu = async () => {
      const query1 = { wsfunction: 'local_seatcreation_sidebar_menu'};
      try {
        const res = await getData(query1);
        if (res.status === 200 && res.data && !res.data.errorcode) {
          setSidebarMenu(res.data as SidebarMenuData);
        }
      } catch (err) {
        console.error(err);
      }
    };

    const fetchSidebarNotifications = async () => {
      const query = { wsfunction: 'local_seatcreation_get_prendingtask_notifications', userid };
      try {
        const res = await getData(query);
        if (res.status === 200 && res.data && !res.data.errorcode) {
          setSidebarNotificationData(res.data as SidebarNotificationData);
        }
      } catch (err) {
        console.error(err);
      }
    };
    
    if (isLoggedIn) {
      fetchSidebarNotifications();
      fetchSidebarMenu();
    }else {
      fetchUrlData();
      fetchPulicSidebarMenu();
    }
  }, [isLoggedIn, userid]);

  return (
    <div id="verticalMainMenu" className="mainmenu-container">


      {sidebarMenu?.htmldata && <div dangerouslySetInnerHTML={{ __html: sidebarMenu.htmldata }} />}


      {/* Notification Section */}
      {isLoggedIn && (
        <div id="verticalMainBell" className="MainMenuBell">

               <div id="bellBox" className="cus-bell-box">

                 <div className="bellAlert">
                   {sidebarNotificationData?.totalnotificationcount > 0 && <span className="countStatus">sidebarNotificationData?.totalnotificationcount</span>}
                   <img src={bellicon} alt="Notification" className="img-fluid bell"/>
                 </div>

                 {sidebarNotificationData?.ltcnotificationcount > 0 && 
                  <div className="ltcAlert">
                   <span className="countStatus">sidebarNotificationData?.ltcnotificationcount</span>
                   <img src={ltcicon} alt="LTC" className="img-fluid" />
                 </div>
                  }
               </div>
                            
                <div className="cus-todo-block" dangerouslySetInnerHTML={{ __html: sidebarNotificationData?.htmldata }} >
                  
                </div>
               
        </div>
      )}
    </div>
  );
};

export default SidebarContent;
