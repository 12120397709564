import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.scss';
import ClipLoader from 'react-spinners/ClipLoader'; // Import spinner
import learningarea from "../../assets/images/Learningarea.png";
import generalapplication from "../../assets/images/generalapplication.png";
import usermanagement from "../../assets/images/Usermanagement.png";
import seatcreation from "../../assets/images/creation.png";
import leave from "../../assets/images/leave.png";
import thesis from "../../assets/images/thesis.png";
import workshop from "../../assets/images/workshop.png";
import bookallowance from "../../assets/images/book.png";
import hra from "../../assets/images/HRA.png";
import ehs from "../../assets/images/EHS.png";
import ltc from "../../assets/images/ltc.png";
import exammanagement from "../../assets/images/exam.png";
import convocation from "../../assets/images/convocation.png";
import { useSelector } from 'react-redux';

const Dashboard = ({ dashboardData, loading }: { dashboardData: any, loading: boolean }) => {
    const userInfo = useSelector((state: any) => state.user.userInfo);

    const imageMap: Record<string, string> = {
        "Learning Area": learningarea,
        "General Application": generalapplication,
        "User Management": usermanagement,
        "Seat Creation": seatcreation,
        "Leave": leave,
        "Thesis": thesis,
        "Conference and Workshop": workshop,
        "Book Allowance": bookallowance,
        "HRA": hra,
        "EHS": ehs,
        "LTC": ltc,
        "Exam Management": exammanagement,
        "Convocation": convocation,
    };

    // Inline styles for loader
    const loaderStyles: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f8f9fa',
    };

    return (
    <div className="contentarea-wrapper">
      {dashboardData && dashboardData?.dashboard?.length > 0 ? (
        <>
          <h2 className="page-title db-title">{dashboardData?.pagetitle}</h2>
          <div className="line-lessmargin"></div>
          <div className="sma-tile mt-3 mb-5">
            <div className="row g-0">
              {dashboardData.dashboard.map((module:any, index:any) => (
                <div key={index} className="col-sm-6 col-md-3 col-lg-3 d-flex">
                  <a href={module.url} className="box">
                    <img
                      src={imageMap[module.modulename] || module.iconurl || ""}
                      alt={module.modulename}
                    />
                    <br />
                    <h6>{module.modulename}</h6>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          {loading ? (
            <>
            <h2 className="page-title db-title">{dashboardData?.pagetitle}</h2>
            <div style={loaderStyles}>
              <ClipLoader color="#007bff" size={60} /> {/* Spinner */}
            </div>
            </>
          ) : (<>
            <h2 className="page-title db-title">{dashboardData?.pagetitle}</h2>
            <div className="alert alert-success text-center" role="alert">
              You haven't enrolled in any courses as of yet.
            </div>
          </>
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
