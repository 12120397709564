import axios, { AxiosInstance } from 'axios';
import config from '../../utils/config';

type RequestData = Record<string, any>;


let REST_ENDPOINT = config.REST_ENDPOINT || ''; 

// Function to wait until MoodleBaseUrl is available in localStorage
const waitForMoodleBaseUrl = async (): Promise<string> => {
  while (!localStorage.getItem('MoodleBaseUrl')) {
    await new Promise((resolve) => setTimeout(resolve, 100)); // Wait 100ms before retrying
  }
  return localStorage.getItem('MoodleBaseUrl') || '';
};

// Initialize Axios instance only after MoodleBaseUrl is available
const createAxiosInstance = async (): Promise<AxiosInstance> => {
  const baseUrl = await waitForMoodleBaseUrl();
  console.log("Creating Axios instance with baseUrl:", baseUrl);

  return axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

// Axios instance (initialized later)
let instance: AxiosInstance;

// Ensure MoodleBaseUrl is available before creating Axios instance
(async () => {
  instance = await createAxiosInstance();
})();

// Update Axios instance if MoodleBaseUrl changes
const updateAxiosInstance = async (): Promise<void> => {
  instance = await createAxiosInstance();
};

// Listen for changes to `MoodleBaseUrl` in localStorage and update Axios instance
window.addEventListener('storage', (event) => {
  if (event.key === 'MoodleBaseUrl') {
    console.log("MoodleBaseUrl changed in localStorage:", event.newValue);
    updateAxiosInstance();
  }
});

// Function to ensure Wstoken is available
const waitForToken = async (): Promise<string> => {
  while (!localStorage.getItem('Wstoken') && !config.WSTOKEN) {
    await new Promise(resolve => setTimeout(resolve, 100)); // Wait 100ms before retrying
  }
  return localStorage.getItem('Wstoken') || config.WSTOKEN;
};

// Function to ensure public token is available
const waitForPublicToken = async (): Promise<string> => {
  while (!localStorage.getItem('publicWebServiceToken')) {
    await new Promise((resolve) => setTimeout(resolve, 100)); // Wait 100ms before retrying
  }
  return localStorage.getItem('publicWebServiceToken') || '';
};

// API Requests

const getPublicData = async (requestData: RequestData, isLoggedIn: boolean) => {
  if (!instance) {
    await updateAxiosInstance();
  }
  const query = { ...requestData };

  if (query.loginrequest) {
    query.service = 'moodle_mobile_app';

    if (!config.TOKEN_ENDPOINT) {
      throw new Error('TOKEN_ENDPOINT is not defined');
    }

    return instance.get(config.TOKEN_ENDPOINT, { params: query });
  }

  query.wstoken = await waitForPublicToken();
  query.moodlewsrestformat = 'json';


  if (!REST_ENDPOINT) {
    throw new Error('REST_ENDPOINT is not defined');
  }

  return instance.get(REST_ENDPOINT, { params: query });
};

const getData = async (requestData: RequestData) => {
  if (!instance) {
    await updateAxiosInstance();
  }
  const query = { ...requestData, wstoken: await waitForToken(), moodlewsrestformat: 'json' };
  console.log("Making GET request with baseUrl:", instance.defaults.baseURL);
  return instance.get(REST_ENDPOINT, { params: query });
};

const postData = async (requestData: RequestData) => {
  if (!instance) {
    await updateAxiosInstance();
  }
  const query = { ...requestData, wstoken: await waitForToken(), moodlewsrestformat: 'json' };
  return instance.post(REST_ENDPOINT, query);
};

const putData = async (requestData: RequestData) => {
  if (!instance) {
    await updateAxiosInstance();
  }
  const query = { ...requestData, wstoken: await waitForToken(), moodlewsrestformat: 'json' };
  return instance.put(REST_ENDPOINT, query);
};

const deleteData = async (requestData: RequestData) => {
  if (!instance) {
    await updateAxiosInstance();
  }
  const query = { ...requestData, wstoken: await waitForToken(), moodlewsrestformat: 'json' };
  return instance.delete(REST_ENDPOINT, { data: query });
};

const processQuizData = async (requestData: RequestData) => {
  if (!instance) {
    await updateAxiosInstance();
  }
  const query = { ...requestData };
  const wstoken = await waitForToken();
  const paramString = `wsfunction=${query.wsfunction}&wstoken=${wstoken}&moodlewsrestformat=json&attemptid=${query.attemptid}&finishattempt=${query.finishattempt}&${query.quizdata}`;
  return instance.get(`${REST_ENDPOINT}?${paramString}`);
};

const getCalendarEvents = async (requestData: RequestData) => {
  if (!instance) {
    await updateAxiosInstance();
  }
  const query = { ...requestData };
  const wstoken = await waitForToken();
  const paramString = `wsfunction=${query.wsfunction}&wstoken=${wstoken}&moodlewsrestformat=json&${query.dataParam}`;
  return instance.get(`${REST_ENDPOINT}?${paramString}`);
};

const signupData = async (requestData: RequestData) => {
  if (!instance) {
    await updateAxiosInstance();
  }
  const query = { ...requestData, wstoken: config.SIGNUP_TOKEN, moodlewsrestformat: 'json' };
  return instance.get(REST_ENDPOINT, { params: query });
};

export {
  getPublicData, getData, postData, putData, deleteData, processQuizData, signupData, getCalendarEvents
};
