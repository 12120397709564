import React, { useEffect, useState } from "react";
import { TFilterUpdate, TUserObj } from "./typescript";
import { getData } from "../../adapters/coreservices";
import UserManagementTable from "./UserManagementTable";
import { useSelector } from "react-redux";
import ChangePasswordModal from "./changePasswordModal";
import AddUserModal from "./AddUserModal";
import { pagination } from "../../utils/pagination";
import BuildPagination from "../../widgets/pagination";
import CommonFilter from "../../globals/filters/commonFilter";

const UserManagement: React.FC = () => {
  const dummyData = { "pager": {}, "items": [] }

  const [users, setUsers] = useState<any>(dummyData);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentUserdata, setCurrentUserdata] = useState<any>("");
  const [showChangePassModal, setShowChangePassModal] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<TUserObj | null>(null);
  const [apiStatus, setApiStatus] = useState("false");
  const [apiError, setError] = useState<any>({});

  const [filterUpdate, setFilterUpdate] = useState<TFilterUpdate>({
    pageNumber: 0,
    pageSize: pagination.PERPAGE,
    sortBy: "",
    sortOrder: "",
    deleted: "",
    enabled: "",
    name: "",
    email: "",
  });
  // const id = useSelector((state: any) => state.user.userId);
  const instituteId = useSelector((state: any) => state.institute?.instituteData?.items[0]?.id) || 0;


  // Fetch user data
  const fetchUsers = () => {
    setApiStatus("true");
    getData(`/${instituteId}/users`, filterUpdate)
      .then((response: any) => {
        if (response.data) {
          setUsers(response.data);
          setApiStatus("false");
          setError("")
        }
      })
      .catch((error) => {
        setApiStatus("false");
        setError(error);
        setUsers(dummyData)
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [filterUpdate]);

  const newPageRequest: (pageRequest: number) => void = (pageRequest: number) => {
    setFilterUpdate({ ...filterUpdate, pageNumber: pageRequest });
  };

  // Modal handling for adding a new user
  const handleEditUser = (user: TUserObj) => {
    setCurrentUser(user); // Set the user to be edited
    setShowModal(true);
  };

  const handleChangePassword = (id: any, email: any, name: any) => {
    setCurrentUserdata({ id, email, name });
    setShowChangePassModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const fields: any = [
    { name: 'name', label: 'Name', placeholder: 'Name', type: 'text' },
    { name: 'email', label: 'Email', placeholder: 'Email', type: 'email' },
    { name: 'status', label: 'Status', placeholder: 'Select Status', type: 'select', options: [{ value: "Active User", name: 'Active User' }, { value: "Deleted User", name: 'Deleted User' }, { value: "Suspended User", name: 'Suspended User' }] },

  ];

  const handleFilterUpdate = (update: any) => {
    const { status, email, name } = update; // Extract the status from the update
    setFilterUpdate(prevFilterUpdate => {
      filterUpdate.pageNumber = 0
      const newFilterUpdate = { ...prevFilterUpdate, pageNumber: 0 }; // Copy the previous state
      if (status === 'Deleted User') {
        newFilterUpdate.deleted = "true"; // Ensure enabled is false when deleted is true
        newFilterUpdate.enabled = "";
        newFilterUpdate.email = email;
        newFilterUpdate.name = name;
      } else if (status === 'Active User') {
        newFilterUpdate.enabled = "true"; // Active users should be enabled
        newFilterUpdate.deleted = "false"; // Active users should not be marked as deleted
        newFilterUpdate.email = email;
        newFilterUpdate.name = name;
      } else if (status === 'Suspended User') {
        newFilterUpdate.enabled = "false"; // Suspended users should be disabled
        newFilterUpdate.deleted = "false"; // Suspended users should not be marked as deleted
        newFilterUpdate.email = email;
        newFilterUpdate.name = name;
      } else if (status === "") {
        newFilterUpdate.deleted = "";
        newFilterUpdate.enabled = "";
        newFilterUpdate.email = email;
        newFilterUpdate.name = name;
      } else if (!status) {
        newFilterUpdate.deleted = "";
        newFilterUpdate.enabled = "";
        newFilterUpdate.email = email;
        newFilterUpdate.name = name;
      }

      // Return the updated filter state
      return newFilterUpdate;
    });
  };


  return (
    <div className="contentarea-wrapper">
      <h2 className="page-title">User Management</h2>

      <CommonFilter updateFilters={handleFilterUpdate} apiStatus={apiStatus} fields={fields} />

      <UserManagementTable data={users.items} 
        onEdit={handleEditUser}
        changePassword={handleChangePassword}
        setFilterUpdate={setFilterUpdate}
        filterUpdate={filterUpdate}
        error={apiError}
        apiStatus={apiStatus} />

      <div className="d-flex justify-content-center mt-3">
        <BuildPagination
          getrequestedpage={newPageRequest}
          activepage={filterUpdate.pageNumber}
          totalpages={users.pager.totalPages}
        />
      </div>

      {showModal && (
        <AddUserModal
          show={showModal}
          onHide={handleModalClose}
          userobj={currentUser || {}}
          currentInstitute={instituteId}
          togglemodalshow={setShowModal}
        />
      )}
      {showChangePassModal && (
        <ChangePasswordModal
          show={showChangePassModal}
          onHide={() => setShowChangePassModal(false)}
          userData={currentUserdata}
        />
      )}
    </div>
  );
};

export default UserManagement;
