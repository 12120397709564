import View from "./view";
import React, { useEffect, useState } from "react";
import { getData } from "../../../adapters/coreservices";

const SimpleMailConfigration = () => {
  const [apiStatus, setApiStatus] = useState("");
  const [mailConfigData, setMailConfigData] = useState([]);
  const [refreshData, setRefreshData] = useState<boolean>(false);

  useEffect(() => {
    setApiStatus("started");
    getData(`/smtp-config`, {})
      .then((result: any) => {
        if (result.data !== "") {
          setMailConfigData(result.data);
        }
        setApiStatus("finished");
      })
      .catch((err: any) => {
        console.log(err);
        setApiStatus("finished");
      });
  }, [refreshData]);

  const refreshToggle = () => {
    setRefreshData(!refreshData);
  };

  return (
    <React.Fragment>
      <View apiStatus={apiStatus} mailConfigData={mailConfigData} />
    </React.Fragment>
  );
};

export default SimpleMailConfigration;
