import SimpleMail from "../../simpleMail";
import { useTranslation } from "react-i18next";
import { Card, Container } from "react-bootstrap";
// import BottomWave from "../../../../../assets/images/background/bg-bottom.svg";

type Props = {
  commonProps: {
    apiStatus: any;
    mailConfigData: any;
  };
};

const Browser = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Container fluid className="mt-3">
        <Card className="p-4 shadow-sm mb-4">
          <SimpleMail
            apiStatus={props.commonProps.apiStatus}
            mailConfigData={props.commonProps.mailConfigData}
          />
        </Card>
      </Container>
    </>
  );
};

export default Browser;
