import "./style.scss";
import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import "sweetalert2/src/sweetalert2.scss";
import SimpleMailConfigration from "./smtp";
import { useTranslation } from "react-i18next";
import PageTitle from "../../widgets/pageTitle";
import { Container, Tabs } from "react-bootstrap";
import MailTempelateConfig from "./mailConfiguration";
const MailTempelateConfiguration: React.FC = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("mail");

  return (
    <React.Fragment>
      <div className="contentarea-wrapper mb-5">
        <Container fluid className="mt-3">
          <PageTitle
            pageTitle="Mail Configuration"
            className="mt-2"
            gobacklink=""
          />
          <Tabs
            id="uncontrolled-tab-example"
            className="tabStep-indicator desk-copo-tabs"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k || "mail")}
          >
            <Tab eventKey="mail" title="Mail Templates">
              <MailTempelateConfig />
            </Tab>
            <Tab eventKey="smtp" title="SMTP">
              <SimpleMailConfigration />
            </Tab>
          </Tabs>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MailTempelateConfiguration;