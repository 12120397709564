import React from "react";
import Browser from "./browser";

type Props = {
  apiStatus: any;
  mailConfigData: any;
};

const View = (props: Props) => {
  const commonProps = {
    apiStatus: props.apiStatus,
    mailConfigData: props.mailConfigData,
  };
  return (
    <React.Fragment>
        <Browser commonProps={commonProps} />
    </React.Fragment>
  );
};

export default View;

